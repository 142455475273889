<template>
  <div id="app">
    <m-header v-if="ifM"></m-header>
    <wl-header v-else></wl-header>
    <router-view />
    <m-footer v-if="ifM"></m-footer>
    <wl-footer v-else></wl-footer>
  </div>
</template>

<script>
import WlHeader from './components/header/wl-header.vue';
import WlFooter from './components/wl-footer.vue';
import MHeader from './mCom/header/m-header.vue';
import MFooter from './mCom/m-footer.vue';
import Store from './store';
export default {
  name: 'app',
  components: {
    WlHeader,
    WlFooter,
    MFooter,
    MHeader,
  },
  mounted() {
    window.myVue = this;
    if (screen.width < 700) {
      this.ifM = true;
      this.$router.push('/M');
    } else {
      this.ifM = false;
      // this.$router.push("/");
    }
    if (window.sessionStorage.getItem('path')) {
      this.$router.push(window.sessionStorage.getItem('path'));
    }
  },
  data() {
    return {
      ifM: false,
      uti: Store.uti,
      store: Store.state,
    };
  },
  methods: {},
};
</script>

<style lang="less">
.w {
  width: 1400px;
  margin: 0 auto;
}
@import url('./assets/style.less');

@font-face {
  font-family: 'ARENO';
  src: url('./assets/fonts/ARENO-SERIF.OTF') format('woff');
}
.areno {
  font-family: 'ARENO';
}
#app {
  // @font-face {
  //   font-family: 'ubuntu';
  //   src: url('./assets/fonts/Ubuntu-L_W.woff2') format('woff2');
  // }
  // @font-face {
  //   font-family: 'SourceHanSansCN-Bold';
  //   src: url('./assets/fonts/SourceHanSansCN-Bold.otf') format('woff2');
  // }
  // @font-face {
  //   font-family: 'SourceHanSansCN';
  //   src: url('./assets/fonts/SourceHanSansCN-Medium.otf') format('woff2');
  // }
  // @font-face {
  //   font-family: 'SourceHanSansCN-Regular';
  //   src: url('./assets/fonts/SourceHanSansCN-Regular.otf') format('woff2');
  // }
  // @font-face {
  //   font-family: 'arialbd';
  //   src: url('./assets/fonts/arialbd.woff') format('woff2');
  // }
  // font-family: SourceHanSansCN;
  // font-family: arialbd, SourceHanSansCN, ubuntu;
  background: #f4faff;
}
.wl-title1 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 76px;
  font-size: 70px;
  font-weight: bold;
  color: #ffffff;
  .gang {
    width: 60px;
    height: 7px;
    background: #ffffff;
  }
  .t {
    margin: 0 40px;
  }
}
.pfont {
  font-family: SourceHanSansCN-Regular;
}
.lan {
  color: #0168ec;
  display: inline-block;
}
.wl-title2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 76px;
  font-size: 70px;
  font-weight: bold;
  color: #000;
  .gang {
    width: 60px;
    height: 7px;
    background: linear-gradient(90deg, #015eea, #00c0fa);
  }
  .t {
    margin: 0 40px;
  }
}
.wl-title3 {
  height: 110px;
  font-size: 70px;
  font-weight: bold;
  color: #333333;
  position: relative;
}
.wl-title3::after {
  position: absolute;
  left: 0;
  bottom: 0;
  content: '';
  width: 60px;
  height: 7px;
  background: linear-gradient(90deg, #015eea, #00c0fa);
}
.wl-item1 {
  width: 330px;
  // height: 472px;
  background: #ffffff;
  border-radius: 10px;
  transition: 0.5s;
  text-align: center;
  position: relative;
  padding: 0 50px 80px;
  box-sizing: border-box;
  .img {
    text-align: center;
    margin-top: 61px;
    img {
      width: 75px;
      height: 75px;
    }
  }
  .t {
    margin-top: 40px;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
  }
  .link {
    width: 52px;
    height: 2px;
    background: #999;
    margin: 17px auto;
  }
  .p {
    color: #999999;
    line-height: 25px;
  }
  .more {
    position: absolute;
    // width: 100%;
    bottom: 56px;
    left: 50%;
    transform: translateX(-50%);
    color: #0168ec;
    a {
      color: #0168ec;
    }
  }
}
.wl-item2 {
  width: 446px;
  min-height: 420px;
  box-shadow: 0px 2px 8px 0px rgba(184, 186, 200, 0.5);
  background: #ffffff;
  border-radius: 10px;
  transition: 0.5s;
  text-align: left;
  position: relative;
  padding: 40px 50px 80px;

  margin-bottom: 22px;
  z-index: 20;
  box-sizing: border-box;
  .img {
    text-align: left;
    img {
      width: 75px;
      height: 75px;
    }
  }
  .t {
    margin-top: 40px;
    font-size: 30px;
    font-weight: bold;
    color: #333333;
  }
  .link {
    width: 52px;
    height: 2px;
    background: #999;
    margin: 17px 0;
  }

  .p {
    color: #999999;
    line-height: 25px;
  }
  .more {
    position: absolute;
    // width: 100%;
    bottom: 40px;
    left: 50px;
    color: #0168ec;
    a {
      color: #0168ec;
    }
  }
}
.wl-item2::after {
  position: absolute;
  content: '';
  z-index: -1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  // background-color: pink;
  // z-index: 19;
  transition: 0.5s;
}
.wl-but {
  display: inline-block;
  padding: 0 42px;
  height: 54px;
  line-height: 54px;
  background: linear-gradient(-90deg, #10c1fc, #3f65f9);
  box-shadow: 0px 5px 20px 0px rgba(184, 205, 233, 0.5);
  border-radius: 27px;
  font-size: 20px;
  font-weight: 400;
  color: #ffffff;
  margin-right: 50px;
}
.m-but {
  height: (39.33 / 3.75vw);
  line-height: (39.33 / 3.75vw);
  // background: #ffffff;
  display: inline-block;
  padding: 0 (30 / 3.75vw);
  border: 1px solid #00bffa;
  box-sizing: border-box;
  color: #333333;
  font-size: (16 / 3.75vw);
  box-shadow: 0px (1.33 / 3.75vw) (6.67 / 3.75vw) 0px rgba(84, 139, 175, 0.5);
  border-radius: (19.67 / 3.75vw);
}
.m-but2 {
  height: (39.33 / 3.75vw);
  line-height: (39.33 / 3.75vw);
  background: linear-gradient(-90deg, #10c1fc, #3f65f9);
  display: inline-block;
  padding: 0 (30 / 3.75vw);
  color: #fff;
  font-size: (16 / 3.75vw);
  border-radius: (19.67 / 3.75vw);
  box-shadow: 0px 5px 20px 0px rgba(184, 205, 233, 0.5);
}
.m-title {
  font-size: (30 / 3.75vw);
  font-weight: Bold;
  text-align: center;
}
.m-title2 {
  font-size: (20 / 3.75vw);
  font-weight: Bold;
  text-align: center;
}
.m-title3 {
  font-weight: Bold;
  text-align: center;
  font-size: (20 / 3.75vw);
  color: #fff;
}
.m-title4 {
  padding: (5 / 3.75vw) 0;
  font-size: (20 / 3.75vw);
  margin: 0 auto;
  display: inline-block;
  font-weight: Bold;
  text-align: center;
  position: relative;
}
.m-title4::after {
  position: absolute;
  right: (10 / 3.75vw);
  bottom: 0;
  content: '';
  width: (39.33 / 3.75vw);
  height: (2.67 / 3.75vw);
  background: linear-gradient(90deg, #015eea, #00c0fa);
}
.m-p {
  font-size: (13.33 / 3.75vw);
  color: #666666;
  line-height: (25 / 3.75vw);
}
.m-item1 {
  width: (167.33 / 3.75vw);
  background: #ffffff;
  transition: 0.5s;
  text-align: center;
  position: relative;
  padding: 0 (20 / 3.75vw) (51 / 3.75vw);
  box-sizing: border-box;
  border-radius: (3.33 / 3.75vw);
  margin-bottom: (12.33 / 3.75vw);

  .img {
    text-align: center;
    margin-top: (34 / 3.75vw);
    img {
      width: (57 / 3.75vw);
      height: (57 / 3.75vw);
    }
  }
  .t {
    margin-top: (31 / 3.75vw);
    font-size: (16 / 3.75vw);
    font-weight: bold;
    color: #333333;
  }
  .link {
    width: (43 / 3.75vw);
    height: (1.33 / 3.75vw);
    background: #999;
    margin: (14 / 3.75vw) auto;
  }
  .p {
    color: #999999;
    font-size: (13.33 / 3.75vw);
    line-height: 25px;
  }
  .more {
    position: absolute;
    // width: 100%;
    font-size: (11.33 / 3.75vw);
    bottom: (23 / 3.75vw);
    left: 50%;
    transform: translateX(-50%);
    color: #0168ec;
    a {
      color: #0168ec;
    }
  }
}
.m-item2 {
  width: (256 / 3.75vw);
  background: #ffffff;
  transition: 0.5s;
  text-align: center;
  position: relative;
  padding: 0 (20 / 3.75vw) (51 / 3.75vw);
  box-sizing: border-box;
  box-shadow: 0px (1.33 / 3.75vw) (2.33 / 3.75vw) 0px rgba(184, 186, 200, 0.5);
  border-radius: (3.33 / 3.75vw);
  margin-bottom: (12.33 / 3.75vw);
  .img {
    text-align: center;
    margin-top: (34 / 3.75vw);
    img {
      width: (57 / 3.75vw);
      height: (57 / 3.75vw);
    }
  }
  .t {
    margin-top: (31 / 3.75vw);
    font-size: (16 / 3.75vw);
    font-weight: bold;
    color: #333333;
  }
  .link {
    width: (43 / 3.75vw);
    height: (1.33 / 3.75vw);
    background: #999;
    margin: (14 / 3.75vw) auto;
  }
  .p {
    color: #999999;
    font-size: (13.33 / 3.75vw);
    line-height: 25px;
  }
  .more {
    position: absolute;
    // width: 100%;
    font-size: (11.33 / 3.75vw);
    bottom: (23 / 3.75vw);
    left: 50%;
    transform: translateX(-50%);
    color: #0168ec;
    a {
      color: #0168ec;
    }
  }
}
.m-item3 {
  width: (348 / 3.75vw);
  background: #ffffff;
  transition: 0.5s;
  text-align: center;
  position: relative;
  padding: 1px (20 / 3.75vw) (51 / 3.75vw);
  box-sizing: border-box;
  box-shadow: 0px (1.33 / 3.75vw) (2.33 / 3.75vw) 0px rgba(184, 186, 200, 0.5);
  border-radius: (3.33 / 3.75vw);
  margin: (20.33 / 3.75vw) auto (12.33 / 3.75vw);
  .img {
    text-align: center;
    margin-top: (34 / 3.75vw);
    img {
      width: (57 / 3.75vw);
      height: (57 / 3.75vw);
    }
  }
  .t {
    margin-top: (31 / 3.75vw);
    font-size: (16 / 3.75vw);
    font-weight: bold;
    color: #333333;
  }
  .link {
    width: (43 / 3.75vw);
    height: (1.33 / 3.75vw);
    background: #999;
    margin: (14 / 3.75vw) auto;
  }
  .p {
    color: #999999;
    font-size: (13.33 / 3.75vw);
    line-height: 25px;
  }
  .more {
    position: absolute;
    // width: 100%;
    font-size: (11.33 / 3.75vw);
    bottom: (23 / 3.75vw);
    left: 50%;
    transform: translateX(-50%);
    color: #0168ec;
    a {
      color: #0168ec;
    }
  }
}
</style>
