<template>
  <div class="header-lang">
    <el-dropdown @command="changeLang">
      <span class="el-dropdown-link">
        <a href="javascript:;">
          <!-- 语言 -->
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#icon-language"></use>
          </svg>
          {{ $t('lang.language') }}
        </a>
      </span>

      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item
          v-for="(item, index) in langList"
          :key="index"
          :command="item"
        >
          {{ item.name }}
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  mounted() {
    this.getLangList();
  },
  data() {
    return {
      langList: [],
      action: {
        name: '',
        code: '',
      },
    };
  },
  methods: {
    // 切换语言
    changeLang(lang) {
      this.action = lang;
      this.$i18n.locale = lang.node;
      window.localStorage.setItem('lang', JSON.stringify(lang));
      // this.down();
    },

    // 获取配置文件上的语言文件
    getLangList() {
      // 遍历配置文件获取语言文件
      for (let i = 0; i < window.wl.lang.length; i++) {
        this.langList.push({
          name: window.wl.lang[i].lang.name,
          node: window.wl.lang[i].lang.node,
        });
      }
      // 如果有选择过语言则使用上次的语言 否则使用第一个作为默认语言
      if (window.localStorage.getItem('lang')) {
        this.action = JSON.parse(window.localStorage.getItem('lang'));
        // console.log(this.action);
        this.$i18n.locale = this.action.node;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.header-lang {
  .el-dropdown-link {
    font-size: 16px;
    color: #fff;
    a {
      color: #fff;
    }
  }
}
</style>
