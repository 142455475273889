<template>
  <div class="menuItem">
    <div class="menuItemT" @click="ifShow = !ifShow">
      <div class="ll">
        <!-- 语言 -->
        {{ $t("lang.language") }}
      </div>
      <div class="rr">
        <svg class="icon" v-if="ifShow" aria-hidden="true">
          <use xlink:href="#icon-xiangyou1"></use>
        </svg>
        <svg class="icon" v-else aria-hidden="true">
          <use xlink:href="#icon-xiangxia2"></use>
        </svg>
      </div>
    </div>

    <el-collapse-transition>
      <div class="menuItemUl" v-if="ifShow">
        <div
          class="menuItemLi"
          v-for="(item, index) in langList"
          :key="index"
          @click="changeLang(item)"
        >
          <div class="box">
            <div class="text">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
import Store from "../../store";
export default {
  mounted() {
    this.getLangList();
  },
  data() {
    return {
      ifShow: false,
      uti: Store.uti,
      store: Store.state,
      langList: [],
      action: {
        name: "",
        code: "",
      },
    };
  },
  methods: {
    // 切换语言
    changeLang(lang) {
      this.action = lang;
      this.$i18n.locale = lang.node;
      window.localStorage.setItem("lang", JSON.stringify(lang));
      // this.down();
    },

    // 获取配置文件上的语言文件
    getLangList() {
      // 遍历配置文件获取语言文件
      for (let i = 0; i < window.wl.lang.length; i++) {
        this.langList.push({
          name: window.wl.lang[i].lang.name,
          node: window.wl.lang[i].lang.node,
        });
      }
      // 如果有选择过语言则使用上次的语言 否则使用第一个作为默认语言
      if (window.localStorage.getItem("lang")) {
        this.action = JSON.parse(window.localStorage.getItem("lang"));
        // console.log(this.action);
        this.$i18n.locale = this.action.node;
      }
    },
  },
};
</script>

<style lang="less" scoped></style>
