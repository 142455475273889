<template>
  <div class="wl-footer">
    <div class="box">
      <div class="ul" v-for="(item, index) in $t('footer.list')" :key="index">
        <div class="li t">{{ item.title }}</div>

        <div
          class="li"
          v-for="(i, ind) in item.ul"
          :key="ind"
          @click="uti.goPath(i.url)"
        >
          {{ i.text }}
        </div>
      </div>
    </div>
    <div class="link"></div>
    <div class="bottom">
      <div class="logo">
        <img src="../assets/images/logo.png" alt="" />PolySmartChain
      </div>
      <div class="tiaoKuan">
        <div class="item">
          <!-- Cookie 政策 -->
          {{ $t("footer.policy") }}
        </div>
        <div class="item">
          <!-- 服务条款 -->
          {{ $t("footer.terms") }}
        </div>
        <div class="item">
          <!-- 隐私政策 -->
          {{ $t("footer.privacy") }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Store from "../store";
export default {
  mounted() {},
  data() {
    return {
      uti: Store.uti,
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.wl-footer {
  .box {
    margin: 0 (13.33 / 3.75vw);
    display: flex;
    flex-wrap: wrap;

    .ul {
      min-width: 50%;
      .li {
        font-size: (12 / 3.75vw);
        margin-top: (6 / 3.75vw);
        font-weight: Medium;
      }
      .t {
        font-size: (13.33 / 3.75vw);
        margin-top: (54 / 3.75vw);
        font-weight: Bold;
      }
    }
  }
  .link {
    width: 100%;
    height: 1px;
    background: #e2e2e2;
    margin-top: (48 / 3.75vw);
  }
  .bottom {
    padding-top: (21 / 3.75vw);
    padding-bottom: (36 / 3.75vw);
    .logo {
      width: 100%;
      justify-content: center;
      display: flex;
      align-items: center;
      img {
        width: (25 / 3.75vw);
      }
    }
    .tiaoKuan {
      justify-content: space-around;
      margin-top: (23 / 3.75vw);
      display: flex;
      align-items: center;
      font-size: (11.33 / 3.75vw);
      color: #999999;
    }
  }
}
</style>
