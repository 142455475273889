<template>
  <div class="header-lang" @mouseenter="onMT()" @mouseleave="onML()">
    <div class="mini">
      <a href="javascript:;">
        <!-- 开发者 -->
        {{ $t("header.dev") }}
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-xiangxia"></use>
        </svg>
      </a>
    </div>
    <transition name="fade">
      <div class="box" v-if="ifShow">
        <a href="javascript:;">
          <div
            class="li"
            v-for="(item,index) in $t('header.devList')"
            :key="index"
            @click="uti.goPath(store.link.header.dev[index])"
          >
            <div class="img">
              <img
                :src="
                  require(`../../assets/images/v4/header/menu3${index + 1}.png`)
                "
                alt=""
              />
            </div>
            <div class="span">
              <div class="name">
                {{ item.title }}
              </div>
              <div class="text">
                {{ item.text }}
              </div>
            </div>
          </div>
        </a>
      </div>
    </transition>
  </div>
</template>

<script>
import Store from "../../store";
export default {
  data() {
    return {
      ifShow: false,
      store: Store.state,
      uti: Store.uti,
      list: [],
    };
  },
  methods: {
    onMT() {
      this.ifShow = true;
    },
    onML() {
      this.ifShow = false;
    },
  },
};
</script>

<style lang="less" scoped></style>
