import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/test',
    component: () => import('../views/test.vue')
  },
  {
    path: '/testM',
    component: () => import('../views/test.vue')
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../components/home')
  },
  {
    path: '/dapp',
    name: 'dapp',
    component: () => import('../components/dapp')
  },
  {
    path: '/com',
    name: 'com',
    component: () => import('../components/community')
  },
  {
    path: '/dev',
    name: 'dev',
    component: () => import('../components/dev')
  },
  {
    // 获取psc
    path: '/getPSC',
    name: 'getPSC',
    component: () => import('../components/use/use-getpsc.vue')
  },
  {
    // 支持钱包
    path: '/supWallet',
    name: 'supWallet',
    component: () => import('../components/use/use-wallet.vue')
  },
  {
    // 学习--桥
    path: '/studyBridge',
    name: 'studyBridge',
    component: () => import('../components/study/study-bridge.vue')
  },
  {
    // 学习--智能合约
    path: '/studyContract',
    name: 'studyContract',
    component: () => import('../components/study/study-contract.vue')
  },
  {
    // 学习--pscToken
    path: '/studyToken',
    name: 'studyToken',
    component: () => import('../components/study/study-psctoken.vue')
  },
  {
    // 学习--psc
    path: '/studyPsc',
    name: 'studyPsc',
    component: () => import('../components/study/study-psc.vue')
  },
  // --------------------------------------------------------------------
  {
    // 移动首页
    path: '/M',
    component: () => import('../mCom/home')
  },
  {
    // 移动社区
    path: '/comM',
    component: () => import('../mCom/community')
  },
  {
    // 移动开发者
    path: '/devM',
    component: () => import('../mCom/dev')
  },
  {
    // 移动Dapp
    path: '/dappM',
    component: () => import('../mCom/dapp')
  },
  {
    // 获取psc
    path: '/getPSCM',
    component: () => import('../mCom/use/use-getpsc.vue')
  },
  {
    // 支持钱包
    path: '/supWalletM',
    component: () => import('../mCom/use/use-wallet.vue')
  },
  {
    // 学习--桥
    path: '/studyBridgeM',
    component: () => import('../mCom/study/study-bridge.vue')
  },
  {
    // 学习--智能合约
    path: '/studyContractM',
    component: () => import('../mCom/study/study-contract.vue')
  },
  {
    // 学习--pscToken
    path: '/studyTokenM',
    component: () => import('../mCom/study/study-psctoken.vue')
  },
  {
    // 学习--psc
    path: '/studyPscM',
    component: () => import('../mCom/study/study-psc.vue')
  },
]

const router = new VueRouter({
  // mode: 'history',
  routes
})
router.afterEach((to, from, next) => {
  console.log(to + from + next);
  window, scrollTo(0, 0)
})


export default router
