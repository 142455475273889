<template>
  <div class="m-header">
    <div
      class="header"
      :class="{
        colorBai: $route.path == '/M',
        bgBai: store.menuIfshow,
        bgLan: !store.ifTop,
      }"
    >
      <img @click="uti.goPath('/')" src="../../assets/images/logo.png" alt="" />
      <div class="ll">
        <span @click="uti.goPath('/')"> PolySmartChain</span>
      </div>
      <div class="rr" @click="store.menuIfshow = !store.menuIfshow">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-caidan"></use>
        </svg>
      </div>
    </div>
    <div class="menuBg" v-if="store.menuIfshow"></div>
    <div class="menuBox" v-if="store.menuIfshow">
      <header-item
        :name="$t('header.usePSC')"
        :list="$t('header.usePSCList')"
        :urls = "store.link.header.usePSC"
        :menu="1"
      ></header-item>
      <header-item
        :name="$t('header.study')"
        :list="$t('header.studyList')"
        :urls = "store.link.header.study"
        :menu="2"
      ></header-item>
      <header-item
        :name="$t('header.dev')"
        :list="$t('header.devList')"
        :urls = "store.link.header.dev"
        :menu="3"
      ></header-item>
      <div class="menuItem" @click="uti.goPath('/com')">
        <div class="menuItemT">
          <div class="ll">
            <!-- 社区 -->
            {{ $t("header.community") }}
          </div>
        </div>
      </div>
      <div class="menuItem" @click="uti.goPath('/dapp')">
        <div class="menuItemT">
          <div class="ll">
            <!-- Dapp -->
            {{ $t("header.dapp") }}
          </div>
        </div>
      </div>
      <div class="menuItem">
        <div class="menuItemT" @click="uti.goPath(store.wardLink)">
          <div class="ll">Doc</div>
        </div>
      </div>
      <!-- <div class="menuItem">
        <div class="menuItemT" @click="uti.goPath('/test')">
          <div class="ll">test</div>
        </div>
      </div> -->
      <header-lang></header-lang>
    </div>
  </div>
</template>

<script>
import Store from "../../store";
import HeaderItem from "./header-item.vue";
import headerLang from "./header-lang.vue";
export default {
  components: { headerLang, HeaderItem },
  mounted() {},
  data() {
    return {
      ifShow: false,
      store: Store.state,
      uti: Store.uti,
    };
  },
  methods: {},
};
</script>

<style lang="less">
.m-header {
  position: relative;
  // width: 100vw;
  // height: (60 / 3.75vw);
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    height: (60 / 3.75vw);
    padding: 0 (13 / 3.75vw);
    // background-color: #fff;
    display: flex;
    align-items: center;
    color: #000;
    z-index: 20;
    img {
      width: (20 / 3.75vw);
      height: (20 / 3.75vw);
    }
    font-size: (10.67 / 3.75vw);
    .ll {
      font-size: (16 / 3.75vw);
      font-weight: Medium;
      flex: 1;
      margin-left: (5 / 3.75vw);
    }
    .rr {
      font-size: (20.67 / 3.75vw);
    }
  }
  .menuBg {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 3;
    top: 0;
    left: 0;
    background-color: #0009;
  }
  .menuBox {
    width: 100%;
    max-height: 95vh;
    overflow: auto;
    box-sizing: border-box;
    padding: 0 (34 / 3.75vw) (64 / 3.75vw);
    background-color: #fff;
    position: fixed;
    top: (60 / 3.75vw);
    z-index: 9;
    left: 0;
    .menuItem {
      .menuItemT {
        height: (56 / 3.75vw);
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #cccccc;
      }
      .menuItemUl {
        .menuItemLi {
          display: flex;
          align-items: center;
          padding: (15 / 3.75vw) 0;
          .img {
            margin-right: (7.67 / 3.75vw);
            img {
              width: (35 / 3.75vw);
              height: (35 / 3.75vw);
            }
          }
          .box {
            flex: 1;
            .name {
              color: #0168ec;
              font-weight: Regular;
              font-size: (13.33 / 3.75vw);
            }
            .text {
              font-weight: Regular;
              color: #999999;
              font-size: (12 / 3.75vw);
            }
          }
        }
      }
    }
  }
  .bgLan {
    background-color: #0167ec9a;
  }
  .colorBai {
    color: #fff;
  }
  .bgBai {
    background-color: #fff;
    color: #000;
  }
}
</style>
