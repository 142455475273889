<template>
  <div class="wl-header" :class="{ shen: !store.ifTop }">
    <div class="w">
      <div class="fl">
        <div class="item">
          <a href="javascript:;">
            <img
              class="logo"
              @click="uti.goPath('/')"
              src="../../assets/images/logo.png"
              alt=""
            />
          </a>
        </div>
        <div class="item">
          <header-usepsc></header-usepsc>
        </div>
        <div class="item">
          <header-study></header-study>
        </div>
        <div class="item">
          <header-dev></header-dev>
        </div>
        <div class="item">
          <a href="javascript:;" @click="uti.goPath('/com')">
            <!-- 社区 -->
            {{ $t("header.community") }}
          </a>
        </div>
        <div class="item" @click="uti.goPath('/dapp')">
          <a href="javascript:;">
            <!-- Dapp -->
            {{ $t("header.dapp") }}</a
          >
        </div>
        <div class="item" @click="uti.goPath(store.wardLink)">
          <a href="javascript:;">
            <!-- Doc -->
            {{ $t("header.doc") }}
          </a>
        </div>
      </div>
      <div class="fr">
        <!-- <div class="item">语言</div> -->
        <div class="item">
          <header-lang></header-lang>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderDev from "./header-dev.vue";
import HeaderLang from "./header-lang.vue";
import HeaderStudy from "./header-study.vue";
import HeaderUsepsc from "./header-usepsc.vue";
import Store from "../../store";
export default {
  components: { HeaderLang, HeaderUsepsc, HeaderStudy, HeaderDev },
  mounted() {
    // const that = this;
    // window.onresize = () => {
    //   return (() => {
    //     window.screenWidth = document.body.clientWidth;
    //     that.screenWidth = window.screenWidth;
    //   })();
    // };
    // 监听到顶部的位置
    window.addEventListener("scroll", this.handleScroll);
  },
  data() {
    return {
      store: Store.state,
      uti: Store.uti,
      screenWidth: document.body.clientWidth,
    };
  },
  methods: {
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop == 0) {
        // console.log(scrollTop);
        this.store.ifTop = true;
      } else {
        this.store.ifTop = false;
      }
    },
  },
};
</script>
<style lang="less">
.wl-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 80px;
  background: #2f7deb96;
  color: #fff;
  font-size: 18px;
  z-index: 98;
  transition: 0.5s;
  .w {
    height: 80px;
    .fl {
      height: 80px;
      display: flex;
      align-items: center;
      .item {
        margin-right: 40px;
        a {
          color: #fff;
        }
        .logo {
          width: 39px;
        }
      }
    }
    .fr {
      height: 80px;
      display: flex;
      align-items: center;
      .item {
        svg {
          font-size: 16px;
        }
        // color: #fff;
      }
    }
    .el-dropdown-link {
      color: #fff;
      font-size: 18px;
    }
  }
  // 下级菜单样式
  .header-lang {
    position: relative;
    .mini {
      height: 80px;
      line-height: 80px;
      a {
        color: #fff;
      }
    }
    .box {
      position: absolute;
      top: 75px;
      width: 720px;
      // height: 428px;
      background: #ffffff;
      box-shadow: 0px 5px 20px 0px rgba(23, 82, 161, 0.5);
      border-radius: 10px;
      padding: 20px 20px;
      box-sizing: border-box;
      display: flex;
      // display: none;
      flex-wrap: wrap;
      justify-content: space-between;
      z-index: 9;

      .li {
        width: 340px;
        // height: 166px;
        // margin-top: 10px;
        // background: #f1f6ff;
        border-radius: 10px;
        color: #000;
        display: inline-flex;
        padding: 10px;
        box-sizing: border-box;
        .img {
          width: 76px;
          padding-top: 13px;
        }
        .span {
          flex: 1;
          .name {
            font-size: 24px;
            margin-top: 13px;
          }
          .text {
            font-size: 14px;
            margin-top: 5px;
            color: #999;
          }
        }
      }
      .li:hover {
        background: #f1f6ff;
        color: #0168ec;
        .text {
          color: #97c1f8;
        }
      }
    }
    .box::after {
      content: "";
      width: 25px;
      height: 30px;
      background-image: url("../../assets/images/v4/header/err.png");
      background-repeat: no-repeat;
      background-size: 100% 100%;
      position: absolute;
      top: -15px;
      left: 40px;
      z-index: 8;
    }
  }
}
.shen {
  background: #2f7deb;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
