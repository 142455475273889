<template>
  <div class="wl-footer">
    <div class="w">
      <div class="footB1">
        <!-- 网站最后更新时间2022年4月8号 -->
        <!-- {{ $t("footer.latestUpdate") }} -->
      </div>
      <div class="box">
        <div class="ul" v-for="(item, index) in $t('footer.list')" :key="index">
          <div class="li t">{{ item.title }}</div>

          <div class="li" v-for="(i, ind) in item.ul" :key="ind">
            <a
              href="javascript:;"
              @click="uti.goPath(store.link.footer.urlList[index][ind])"
            >
              <!-- 这里根据位置来判断是否为  联系我们  如果文档里面改了就要改这里的位置 -->
              <el-popover
                v-if="index == 5 && ind == 3"
                placement="top-start"
                trigger="hover"
                content="polysmartchain@gmail.com"
              >
                <el-button slot="reference">{{ i.text }}</el-button>
              </el-popover>
              <span v-else>{{ i.text }}</span>
            </a>
          </div>
        </div>
      </div>
      <div class="link"></div>
      <div class="bottom">
        <div class="fl logo">
          <img src="../assets/images/logo.png" alt="" />PolySmartChain
        </div>
        <div class="fl">
          <!-- © 2022 PSC网络版权所有。 -->
          {{ $t('footer.copyright') }}
        </div>
        <div class="fr">
          <!-- Cookie 政策 -->
          {{ $t('footer.policy') }}
        </div>
        <div class="fr">
          <!-- 服务条款 -->
          {{ $t('footer.terms') }}
        </div>
        <div class="fr">
          <!-- 隐私政策 -->
          {{ $t('footer.privacy') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Store from '../store';
export default {
  mounted() {},
  data() {
    return {
      uti: Store.uti,
      store: Store.state,
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.wl-footer {
  margin-top: -250px;
  height: 636px;
  padding-top: 120px;
  box-sizing: border-box;
  color: #999999;
  .w {
    .footB1 {
      margin-bottom: 30px;
      font-weight: 600;
      color: #333333;
      font-size: 16px;
      font-weight: 400;
      color: #333333;
    }
    .box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .ul {
        display: inline-block;
        .li {
          line-height: 30px;
          font-size: 16px;
          font-weight: 500;
          color: #999999;
        }
        .t {
          margin-bottom: 20px;
          font-size: 16px;
          font-weight: bold;
          color: #333333;
        }
      }
    }
    .link {
      border-bottom: 2px solid #e2e2e2;
      margin-top: 50px;
    }
    .bottom {
      height: 114px;
      line-height: 114px;
      .logo {
        color: #333333;
        font-weight: 600;
      }
      .fl {
        margin-right: 89px;
        display: flex;
        align-items: center;
        img {
          width: 42px;
          height: 42px;
          float: left;
        }
      }
      .fr {
        margin-left: 53px;
      }
    }
  }
}
</style>
