<template>
  <div class="menuItem">
    <div class="menuItemT" @click="ifShow = !ifShow">
      <div class="ll">
        {{ name }}
      </div>
      <div class="rr">
        <svg class="icon" v-if="ifShow" aria-hidden="true">
          <use xlink:href="#icon-xiangyou1"></use>
        </svg>
        <svg class="icon" v-else aria-hidden="true">
          <use xlink:href="#icon-xiangxia2"></use>
        </svg>
      </div>
    </div>

    <el-collapse-transition>
      <div class="menuItemUl" v-if="ifShow">
        <div
          class="menuItemLi"
          v-for="(item, index) in list"
          :key="index"
          @click="uti.goPath(urls[index])"
        >
          <div class="img">
            <img
              :src="
                require(`../../assets/images/v4/header/menu${menu}${
                  index + 1
                }.png`)
              "
              alt=""
            />
          </div>
          <div class="box">
            <div class="name">{{ item.title }}</div>
            <div class="text">{{ item.text }}</div>
          </div>
        </div>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
import Store from '../../store';
export default {
  mounted() {},
  props: {
    name: {
      type: String,
    },
    menu: {
      type: Number,
    },
    list: {
      type: Array,
    },
    urls: {
      type: Array,
    },
  },
  data() {
    return {
      ifShow: false,
      uti: Store.uti,
      store: Store.state,
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped></style>
