import Vue from 'vue'
import { Carousel, CarouselItem, Dropdown, DropdownItem, DropdownMenu, Message, Popover, Slider } from 'element-ui'

import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
// Vue.use(Button)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Dropdown)
Vue.use(Slider)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Popover)

Vue.component(CollapseTransition.name, CollapseTransition)

Vue.prototype.$message = Message;